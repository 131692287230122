.container {
    position: fixed;
    top: 64px;
    left: 0px;
    width: 100vw;
    height: 54px;
    display: flex;
    color: #FFF;
    font-family: "PP NeueBit";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    justify-content: space-between;
    background: black;
    z-index: 1000;
}

.goto {
    display: flex;
    padding: 4px;
    align-items: center;
    border: 0.5px solid rgba(255, 255, 255, 0.48);
    box-sizing: content-box;
}

.section, .selectedSection {
    width: 100%;
    margin: 4px 2px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 32px;
    transition: 0.1s ease-out;
    user-select: none;
}

.section:hover {
    cursor: pointer;
    background: rgba(136, 136, 136, 0.16);
}

.selectedSection:hover {
    cursor: pointer;
}

.container > :first-child {
    margin-left: 4px;
}

.container > :last-child {
    margin-right: 4px;
}

.section {
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.selectedSection {
    border: 1px solid rgba(255, 255, 255, 0.48);
    background: rgba(136, 136, 136, 0.18);
}

@media (max-width: 700px) {
    .container {
        display: none;
    }
}