.container, .mobileContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    height: calc(100dvh - 64px - 54px);
    padding: 0px;
    box-sizing: border-box;
}

.mobileContainer {
    height: calc(100dvh - 64px);
    padding: 10px;
}

.mobileContainer .moreWrapper {
    display: none;
}

.glyph {
    height: 12dvh;
}

.hero {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: clamp(1rem, 4dvh, 48px);
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.52);
    box-sizing: border-box;
    flex-shrink: 0;
    overflow: hidden;
    width: 85vw;
    max-width: 490px;
}

.heroWrapper {
    padding: 8px;
    /* max-width: 650px; */
    /* width: 85%; */
    height: 90%;
    max-height: 700px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.tagline {
    width: 100%;
    text-align: center;
    font-family: "P22 Mackinac";
    font-size: 43px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.44px;
    box-sizing: border-box;
    flex-shrink: 0;
    z-index: 10;
    max-width: 350px;
}

.mobileContainer .tagline {
    font-size: 36px;
}

.moreWrapper {
    display: flex;
    width: 60%;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    transition: 0.1s ease-out;
    flex-shrink: 0;
    z-index: 100;
    pointer-events: all !important;
}

.moreWrapper:hover {
    cursor: pointer;
    animation: flash 1s;
}

@keyframes flash {
    0%, 100% {
        background: none;
    }    
    30% {
        background: rgba(136, 136, 136, 0.16);
    }
}

.more {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    box-sizing: border-box;
    overflow: visible;
}

.more p {
    color: #FFF;
    font-family: "PP NeueBit";
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
}

.moreWrapper::before, .moreWrapper::after {
    content: '';
    width: 100%;
    height: 1px;
    background: white;
    opacity: 0.2;
}

.goto {
    width: 17px;
    height: 17px;
    display: flex;
    padding: 4px;
    align-items: center;
    border: 0.5px solid rgba(255, 255, 255, 0.48);
    position: relative;
    box-sizing: content-box;
}

.arrow1, .arrow2 {
    opacity: 0;
    position: absolute;
    height: 12px;
    width: 12px;
    top: 4px;
    left: 7px;
    box-sizing: content-box;
}

.arrow1 {
    animation: arrow-movement 1.8s 0.9s ease-in-out infinite;
}
.arrow2 {
    animation: arrow-movement 1.8s ease-in-out infinite;
}

.arrow1:before,
.arrow1:after,
.arrow2:before,
.arrow2:after {
    background: #fff;
    content: '';
    display: block;
    height: 1px; 
    position: absolute;
    width: 12px;
    top: 0;
    left: 0;
}

.arrow1:before, .arrow2:before {
    transform: rotate(45deg) translateX(-23%);
    transform-origin: top left;
}

.arrow1:after, .arrow2:after {
    transform: rotate(-45deg) translateX(23%);
    transform-origin: top right;
}

@keyframes arrow-movement {
    0% { 
        transform: translateY(0px);
        opacity: 0;
    }
    70% {
        transform: translateY(4px);
        opacity: 1;
    }
    99% {
        transform: translateY(7px);
        top: 28%;
    }
    100% {
        opacity: 0;
        transform: translateY(0px);
    }
}

.bigger {
    font-style: italic;
    display: inline-block;
    margin-right: 6px;
}

.bits {
    color: #CDE9D4;
}

@media (max-width: 700px) {
    .container {
        height: calc(100dvh - 64px - 74px);
    }
}

@media (max-width: 500px) {
    .tagline {
        padding: 0;
    }
    .moreWrapper {
        width: 100%;
    }
}