.container {
    background: white;
    height: 64px;
    width: 100vw;
    display: flex;
    align-items: center;
    color: #000;
    font-family: "PP NeueBit";
    font-size: 16px;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
}

.container, .container * {
    pointer-events: all !important;
}

.titleWrapper {
    position: absolute;
    width: 100vw;
    text-align: center;
    pointer-events: none;
}

.title {
    position: relative;
}

/* .title::after {
    content: '';
    position: absolute;
    display: inline-block;
    background-image: url('../../public/images/logo.svg');
    background-repeat: no-repeat;
    margin-left: 40px;
    margin-top: -4px;
    width: 36px;
    height: 18px;
} */
/* 
.mobileTitle {
    display: inline-block;
    background-image: url('../../public/images/logo.svg');
    background-repeat: no-repeat;
    margin-top: -4px;
    width: 18px;
    height: 18px;
    margin: auto;
} */

.year {
    position: absolute;
    margin-left: 64px;
    width: calc(50vw - 64px - 140px);
    text-align: center;
    pointer-events: none;
}

.toggleWrapper {
    background: #CDE9D4;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    z-index: 10000;
}

.toggleWrapper:hover {
    cursor: pointer;
}

.toggleWrapper svg {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
}

.open svg {
    transform: rotate(45deg);
}

.logo {
    display: block;
    width: 24px;
    margin-right: 22px;
}

.mobileTitle {
    display: none;
}

@media (max-width: 800px) {
    .year {
        display: none;
    }
    .mobileTitle {
        display: block;
    }
    .title {
        display: none;
    }
}

@media (max-width: 500px) {
    .logo {
        width: 32px;
        margin-right: 16px;
    }
}