.container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overscroll-behavior: none;
    margin-bottom: 0;
    background: black;    
}

.container * {
    pointer-events: none;
    overscroll-behavior: none;
}

.fadeInWrapper {
    all: unset;
    display: contents;
}

.sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: scroll;
    height: max-content;
    align-items: center;
    gap: 10dvh;
    overscroll-behavior: none;
    margin-bottom: 0;
}