.container, .mobileContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    max-width: 870px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: visible;
}

.mobileContainer {
    width: 88vw;
    padding: 12px;
}

.innerBorder {
    border: 1px solid rgba(255, 255, 255, 0.62);
    padding: 32px 48px;
    overflow: visible;
}

.text {
    color: #FFF;
    text-align: center;
    font-family: "P22 Mackinac";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.96px;
}

.mobileContainer {
    margin-bottom: 250px;
}

.mobileContainer .innerBorder {
    padding: 32px 30px;
}

.mobileContainer .text {
    font-size: 22.719px;
}