.container {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100vw;
    height: calc(100dvh - 64px);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    overflow: hidden;
}

.container, .container * {
    pointer-events: all !important;
}

.selectionWrapper {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.selection {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
}

.title {
    color: #FFF;
    font-family: "P22 Mackinac";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.44px;
    margin: 0;
}

.contact {
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: 300px;
    height: 270px;
    border-radius: 30.282px 30.282px 0px 30.282px;
    border: 1px dashed #F2F2F2;
    padding: 6px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.contact .title {
    border-radius: 24px 24px 0px 24px;
    border: 0.4px solid rgba(0, 0, 0, 0.24);
    background: #FFF;
    color: #1A3833;
    font-family: "P22 Mackinac";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.682px;
    text-transform: capitalize;
    height: 72px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding-left: 36px;
    margin: 0;
}

.email {
    color: #FFF;
    font-family: "P22 Mackinac";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.682px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.year {
    display: flex;
    padding: 0px 27px 14px 27px;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
    color: #FFF;
    font-family: "PP NeueBit";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin: 0;
}

.section, .selectedSection {
    width: 100%;
    margin-top: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.1s ease-out;
    user-select: none;
    text-decoration: none;
}

.selectedSection {
    background: rgba(136, 136, 136, 0.16);
}

.sectionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 32px;
    box-sizing: border-box;
    width: 100%;
}

.sections {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    margin: 0;
}

.sections::before, .sections::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background: white;
    opacity: 0.2;
}

.sections::after {
    margin-top: 4px;
}

.section::before, .section::after, .selectedSection::before, .selectedSection::after {
    content: '';
    width: 100%;
    height: 1px;
    background: white;
    opacity: 0.2;
}

.sectionTitle {
    color: #FFF;
    font-family: "PP NeueBit";
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 32px 0px;
}

.section:hover {
    cursor: pointer;
    background: rgba(136, 136, 136, 0.08);
}

.selectedSection:hover {
    cursor: pointer;
}


.goto {
    display: flex;
    padding: 4px;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.48);
    box-sizing: content-box;
}

@media (max-width: 1200px) {
    .selectionWrapper {
        flex-direction: row;
        width: 80vw;
        gap: 60px;
    }

    .selection {
        flex: 1.5;
    }

    .contact {
        margin-top: 104px;
        position: relative;
        flex: 1;
        right: 0;
        bottom: 0;
    }
}

@media (max-width: 700px) {
    .selectionWrapper {
        flex-direction: column;
        width: 80vw;
        gap: 20px;
    }

    .selection {
        flex: 1;
    }

    .sectionTitle {
        margin: 24px 0;
    }

    .contact {
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        right: 0;
        bottom: 0;
        margin-top: 20px;
        width: 80vw;
        box-sizing: border-box;
    }

    .year {
        display: none;
    }

    .contact .title {
        padding: 0px 32px;
    }

    .contact .email {
        padding: 0 32px;
    }
}

@media (max-width: 550px) {
    .contact {
        flex-direction: column;
        margin: 0;
        height: 40px;
    }
    .contact .title, .contact .email {
        height: 60px;
    }
    .title {
        width: -webkit-fill-available;
    }
    .email {
        margin: 0;
        justify-content: flex-start;
        width: -webkit-fill-available;
    }
}

@media (max-height: 700px) and (max-width: 700px) {
    .title {
        font-size: 36px;
    }
    .contact {
        display: none;
    }
}