.wrapper {
    width: 100vw;
    height: 100%;
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container, .mobileContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 32px 32px 0px 32px;
    border: 1px dashed #F2F2F2;
    display: flex;
    flex-direction: row;
    padding: 7px;
    box-sizing: border-box;
    gap: 8px;
    justify-content: center;
    overflow: visible;
    position: relative;
}

.container {
    height: calc(100dvh - 64px - 54px - 5dvh);
    max-height: 600px;
    background: black;
}

.mobileContainer, .mobileContainer * {
    pointer-events: none;
}

.container div, .mobileContainer div {
    box-sizing: border-box;
}

.blueOverlay {
    position: fixed;
    top: -75dvh;
    right: -70vw;
    width: 200vw;
    height: 130dvh;
    pointer-events: none;
    mix-blend-mode: hard-light;
}

.dottedWrapper {
    width: 80vw;
    max-width: 900px;
    height: 100%;
    border-radius: 24px 24px 0px 24px;
    border: 1px dashed #F2F2F2;
    display: flex;
    flex-direction: row;
    padding: 8px;
    box-sizing: border-box;
    gap: 8px;
    justify-content: center;
    background: black;
}

.mobileContainer .dottedWrapper {
    padding: 4px;
    gap: 12px;
    width: calc(88vw - 24px);
}

.intro {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-end;
    padding: 50px;
    flex: 2;
    height: auto;
    border-radius: 24px 24px 0px 24px;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    background: #FFF;
}

.title {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #0C3933;
    font-family: "P22 Mackinac";
    font-size: 22.719px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.682px;
}

.top p {
    margin: 0;
}

.divider {
    width: 100%;
    height: 1px;
    background: black;
    margin: 0;
    opacity: 0.24;
}

.intro .text .body:first-of-type::first-letter {
    font-family: 'P22 Mackinac';
    font-size: 48px;
}

.body {
    font-family: "Post Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}

.intro .body {
    color: #0C3933;
}

.panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex: 1.4;
    height: auto;
}

.heading {
    font-family: "P22 Mackinac";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.68px;
    margin: 0;
}

.mission {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 24px;
    border: 1px solid #C6EAD2;
    padding: 40px;
    gap: 16px;
    background: black;
}

.mission .body, .mission .heading {
    color: #C6EAD2;
}

.tagline {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 24px;
    background: #C6EAD2;
    padding: 40px;
    flex-grow: 1;
    text-align: center;
}

.tagline .heading {
    color: #0C3933;
}

.tagline .heading::before, .tagline .heading::after {
    content: '';
    height: 18px;
    width: 1px;
    background: black;
    display: inline-block;
}

.tagline .heading::before {
    margin-right: 6px;
}

.tagline .heading::after {
    margin-left: 6px;
    margin-bottom: -2px;
}

.italic {
    font-style: italic;
}

.newline {
    margin-top: 10px;
}

.intro .text .body {
    margin-top: 14px;
}


@media (max-width: 700px) {
    .dottedWrapper {
        flex-direction: column;
        padding: 0;
        border: none;
    }
    .dottedWrapper {
        background: transparent;
    }
    .container {
        background: transparent;
        padding: 8px;
    }
    .intro {
        padding: 36px;
    }
    .body {
        font-size: 16px !important;
    }
}

@media (max-width: 500px) {
    .body {
        font-size: 15px;
    }
    .dottedWrapper {
        background: transparent;
    }
    .container {
        background: transparent;
    }
    .wrapper {
        width: auto;
        height: auto;
        background: none;
    }
}

@media (max-height: 800px) {
    .dottedWrapper {
        max-width: 930px;
    }
}

@media (max-width: 700px) {
    .container {
        height: calc(100dvh - 64px - 5dvh);
    }
}

@media (max-width: 950px) {
    .container .tagline {
        display: none;
    }
    .container * {
        flex-shrink: 0;
    }
    .container .panel {
        display: none;
    }
}