.scrollableContainer {
    display: flex;
    justify-content: center;
    width: 100vw;
    pointer-events: all;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollableContainer::-webkit-scrollbar {
    display: none;
}

.top {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 10px;
}

.scrollInto {
    position: fixed;
    top: 30px;
    left: 0;
    width: 100vw;
    height: 10px;
}

.bottom {
    position: absolute;
    width: 100vw;
    height: 10px;
    bottom: -30dvh;
}

.columnContainer {
    display: flex;
    flex-direction: row;
}

.container, .mobileContainer {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    width: 80vw;
    background: white;
    box-sizing: border-box;
    padding: 24px;
    position: relative;
    overflow: visible;
    margin-bottom: 20dvh;
}

.mobileContainer {
    width: 100vw;
    margin: 0;
    margin-top: 10dvh;
}

.mobileContainer .title {
    margin-top: 20px;
    font-size: 32px;
}

.innerBorder {
    padding: 8px;
    height: 100%;
    width: -webkit-fill-available;
    border: 1px dotted gray;
    box-sizing: content-box;
    padding: 60px;
    padding-top: 0;
    background-image: url('../../public/images/horizontal-gradient.webp');
    background-size: cover;
    background-repeat: no-repeat;
}

.mobileContainer .innerBorder {
    width: calc(85vw - 32px);
}

/* .ditherFade {
    margin-top: -64px;
    margin-left: -64px;
    mix-blend-mode:darken;
} */

.ditherFade {
    margin-left: -64px;
    margin-bottom: 24px;
    width: 180%;
}

.title {
    color: #0C3933;
    font-family: "P22 Mackinac";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -1.44px;
}

.green {
    color: color(display-p3 0 0.6811 0.5177);
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: visible;
}

.container .column:first-child {
    margin-top: 62px;
}

.section {
    position: relative;
    display: flex;
    flex-direction: column;
    border-top: 1px dashed #426A72;
    border-left: 1px dashed #426A72;
    border-right: 1px dashed #426A72;
    padding: 24px 48px;
    overflow: visible;
}

.section:last-of-type {
    border-bottom: 1px dashed #426A72;
}


.heading {
    color: #0C3933;
    font-family: "P22 Mackinac";
    font-size: 16px;
    font-style: normal;
    line-height: 26px;
}

.body {
    color: #0C3933;
    font-family: "Post Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

/* .arrows {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    width: 28px;
    gap: 26dvh;
} */

.arrow {
    position: absolute;
    width: 28px;
    top: calc(50% - 14px);
    right: -14px;
    z-index: 1;
}

.arrowBetween {
    display: none;
    width: 28px;
}

.gradient {
    position: fixed;
    top: 0;
    right: 0;
    display: none;
    mix-blend-mode: hard-light;
    pointer-events: none;
}

.decor {
    z-index: 100;
}

.rocket {
    position: absolute;
    left: -70px;
    top: 370px;
    /* transform: rotate(0deg); */
    width: 120px;
    /* animation: rocket-rotate 3s infinite ease-out; */
    cursor: grab;
}

@keyframes rocket-rotate {
    0%, 100% {
        transform: rotate(0deg);
    }
    33% {
        transform: rotate(-2deg) translateY(3px);
    }
    66% {
        transform: rotate(-4deg) translateY(10px);
    }
}

.darpaTag {
    position: absolute;
    left: -90px;
    top: 550px;
    width: 160px;
    cursor: grab;
    /* animation: darpa-tag-rotate 4s infinite ease-out; */
}

@keyframes darpa-tag-rotate {
    0%, 100% {
        transform: rotate(0deg);
    }
    33% {
        transform: rotate(-2deg) translateY(5px);
    }
    66% {
        transform: rotate(0deg) translateY(8px);
    }
}

.photoMuniza {
    position: absolute;
    right: 2px;
    top: 165px;
    width: 100px;
    cursor: grab;
    /* transform: rotate(-21deg); */
    /* animation: photo-muniza-rotate 4s infinite ease-out; */
}

@keyframes photo-muniza-rotate {
    0%, 100% {
        transform: rotate(0deg);
    }
    33% {
        transform: rotate(-2deg);
    }
    66% {
        transform: rotate(-3deg);
    }
}

.photoEmma {
    position: absolute;
    right: -80px;
    top: 210px;
    width: 100px;
    cursor: grab;
    /* transform: rotate(30deg);
    animation: photo-emma-rotate 3s infinite ease-out; */
}

@keyframes photo-emma-rotate {
    0%, 100% {
        transform: rotate(30deg);
    }
    33% {
        transform: rotate(33deg);
    }
    66% {
        transform: rotate(38deg);
    }
}

.darpaTagBack {
    position: absolute;
    right: -90px;
    top: 350px;
    width: 150px;
    cursor: grab;
    /* transform: rotate(-15deg);
    animation: darpa-tag-back-rotate 5s infinite ease-out; */
}

@keyframes darpa-tag-back-rotate {
    0%, 100% {
        transform: rotate(-15deg);
    }
    33% {
        transform: rotate(-15deg) translateY(5px);
    }
    66% {
        transform: rotate(-18deg) translateY(12px);
    }
}

@media (max-width: 800px) {
    .columnContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .column:first-child {
        order: 2;
    }
    .column:last-child {
        order: 1;
    }
    .arrowBetween {
        display: block;
        position: relative;
        margin: auto;
    }
    .arrow {
        display: none;
    }
    .section {
        border: 1px dashed #426A72;
    }
}

@media (max-width: 500px) {
    .innerBorder {
        padding: 24px;
    }
    .section {
        padding: 24px;
    }
    .heading {
        margin: 0;
    }
    .body {
        margin: 0;
        margin-top: 16px;
    }
    .mobileContainer .title {
        margin-top: -20px;
    }
}