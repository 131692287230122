.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background: black;
}

.front {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
}

.capturePointerEvents {
    z-index: 100;
    pointer-events: none;
}

.scrollableChild {
    z-index: 100;
    pointer-events: none;
}

.sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    gap: calc((64px + 28px) * 1.5);
    margin-top: 118px;
    height: calc(100dvh - 64px - 54px)
}

.scrollableContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    z-index: 10;
    scroll-snap-type: y mandatory;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollableContainer::-webkit-scrollbar {
    display: none;
}

.scrollable {
    width: 100vw;
    height: 100dvh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.docsContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    pointer-events: none;
    overflow: hidden;
}

.introWrapper {
    overflow: visible;
}

@media (max-width: 700px) {
    .sections {
        margin-top: 64px;
        height: calc(100dvh - 64px);
    }
}

@media (max-width: 500px) {
    .sections {
        margin-top: 128px;
        gap: 0px;
        margin-bottom: 8ch;
    }
}