.scrollableContainer {
    width: 100vw;
    display: flex;
    justify-content: center;
    pointer-events: all;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollableContainer::-webkit-scrollbar {
    display: none;
}

.top {
    position: absolute;
    width: 100vw;
    height: 10px;
    top: 0;
}

.scrollInto {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100vw;
    height: 10px;
}

.bottom {
    margin-top: 80px;
    width: 100vw;
    height: 10px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    width: 80vw;
    max-width: 1000px;    
    margin-top: 140px;
    overflow: visible;

}

.mobileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background: white;
    background-image: url('../../public/images/programs-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0px;
}

.mobileContainer .container {
    margin-top: 0;
    gap: 40px;
}

.mobileContainer .section {
    padding: 24px;
}

.blueOverlay {
    position: fixed;
    top: -36dvh;
    right: -70vw;
    width: 200vw;
    height: 130dvh;
    pointer-events: none;
    mix-blend-mode: hard-light;
}

.header {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 80vw;
}

.divider {
    width: 100%;
    height: 1px;
    background: black;
    opacity: 0.24;
    margin: 28px 0px;
}

.intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    color: #0C3933;
    font-family: "P22 Mackinac";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.96px;
    margin: 0;
}

.body {
    color: rgba(12, 57, 51, 0.70);
    font-family: "Post Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}

.contactContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
    background: #000;
    align-items: center;
    width: 80vw;
    max-width: 150px;
    padding: 19px 32px;
    height: max-content;
    pointer-events: all !important;
    text-decoration: none;
    z-index: 100;
    box-sizing: content-box;
}

.contactContainer, .contactContainer * {
    pointer-events: all !important;
}

.contact {
    color: #F3F3F3;
    font-family: "PP NeueBit";
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    margin-top: 3px;
    text-decoration: none;
}

.contactContainer:hover {
    cursor: pointer;
    animation: flash 1s;
}

@keyframes flash {
    0%, 100% {
        background: black;
    }    
    30% {
        background: rgb(34, 45, 48);
    }
}

.goto {
    display: flex;
    padding: 4px;
    align-items: center;
    border: 0.5px solid rgba(255, 255, 255, 0.48);
    box-sizing: content-box;
}

.main {
    background: white;
    padding: 20px;
    width: 100%;
    margin-left: -20px;
    box-sizing: content-box;
}

.ditherFade {
    width: 180%;
}

.innerBorder {
    border: 1px dashed #000;
}

.sections {
    background-image: url('../../public/images/vertical-gradient.webp');
    background-size: 500%;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.section {
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-top: 1px dashed #000;
    padding: 48px;
}

.section:nth-child(odd) {
    border-right: 1px dashed #000;
}

.section:nth-child(4) {
    border-bottom: 1px dashed #000;
}

.heading {
    color: #07302b;
    font-family: "P22 Mackinac";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}

.body {
    color: rgba(5, 30, 27, 0.8);
    font-family: "Post Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin: 0;
}

@media (max-width: 800px) {
    .sections {
        display: flex;
        flex-direction: column;
        background-size: 100% 240%;
    }
}

@media (max-width: 700px) {
    .body {
        line-height: 26px;
    }
}

@media (max-width: 560px) {
    .intro {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .contactContainer {
        width: 100%;
        max-width: -webkit-fill-available;
    }
    
    .divider {
        margin: 24px 0px;
    }
}