* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  overflow-x: hidden;
}

div {
  overflow-x: hidden;
}

img {  
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

::-webkit-scrollbar {
  display: none;
}

#home, #about, #team, #programs {
  scroll-margin-top: 50px;
}

@font-face {
  font-family: "PP NeueBit";
  src: url("../public/fonts/PPNeueBit-Bold.otf");
}

@font-face {
  font-family: "P22 Mackinac";
  src: url("../public/fonts/P22Mackinac-Book.otf");
}

@font-face {
  font-family: "P22 Mackinac Bold";
  src: url("../public/fonts/P22Mackinac-Bold_23.otf");
}

@font-face {
  font-family: "Post Grotesk";
  src: url("../public/fonts/PostGrotesk-Book.ttf");
}

video::-webkit-media-controls {
  display: none;
  -webkit-appearance: none;
}

::selection {
  background: #39d5cb69;
}

/**
 * Remove focus styles for non-keyboard focus.
 */
 :focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}