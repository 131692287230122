.container, .mobileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobileContainer .challengeContainer, .mobileContainer .visionContainer, .mobileContainer .descContainer {
    width: 88vw;
}

.challengeContainer, .visionContainer, .descContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    max-width: 800px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 8px;
    box-sizing: border-box;
    overflow: hidden;
    height: max-content;
}

.challengeContainer p {
    justify-self: center;
    text-align: center;
}

.visionContainer p {
    justify-self: center;
    text-align: center;
}

.em {
    color: #C6EAD2;
}

.connector {
    flex-grow: 1;
    border-right: 1px dotted rgba(255, 255, 255, 0.62);
}

.contextInnerBorder, .descInnerBorder {
    border: 1px solid rgba(255, 255, 255, 0.62);
    padding: 32px 48px;
    width: 100%;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.challengeTitle, .visionTitle {
    color: #FFF;
    font-family: "PP NeueBit";
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    justify-self: center;
    pointer-events: all !important;
    cursor: crosshair;
    margin: 0;
    margin-bottom: 16px;
}

.challengeTitle {
    width: 145px;
}

.visionTitle {
    width: 115px;
}

.text {
    color: #FFF;
    text-align: center;
    font-family: "P22 Mackinac";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.96px;
    margin: 0;
}

.mobileContainer .text {
    font-size: 22.7px;
}

.subtext {
    color: rgba(247, 251, 255, 0.90);
    text-align: center;
    font-family: "Post Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0;
}