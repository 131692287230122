
.grain, .grain2 {
    position: fixed;
    top: 64px;
    left: 0;
    height: calc(100dvh - 64px);
    width: 100%;
    pointer-events: none;
    transition: all 0s;
    z-index: 1000;
}

.grain {
    opacity: 0.6;
    mix-blend-mode: overlay;
}

.grain2 {
    opacity: 0.4;
    mix-blend-mode: screen;
}

.grain::before, .grain2::before {
    content: "";
    top: -10rem;
    left: -10rem;
    width: calc(100% + 20rem);
    height: calc(100% + 20rem);
    position: fixed;
    background-image: url('../../public/images/grain.svg');
    pointer-events: none;
    animation: noise 1s steps(2) infinite;
    mask-image: linear-gradient(30deg, transparent, black);
}

.grain2::before {
    background-image: url(https://upload.wikimedia.org/wikipedia/commons/5/5c/Image_gaussian_noise_example.png);
}
  
@keyframes noise {
    0% { transform: translate3d(0, 2rem, 0) }
    10% { transform: translate3d(-1rem, -2rem, 0) }
    20% { transform: translate3d(-4rem, 1rem, 0) }
    30% { transform: translate3d(4.5rem, -4.5rem, 0) }
    40% { transform: translate3d(-1rem, 3.5rem ,0) }
    50% { transform: translate3d(-4.5rem, -2rem, 0) }
    60% { transform: translate3d(1rem, 3rem, 0) }
    70% { transform: translate3d(3.5rem, -4rem, 0) }
    80% { transform: translate3d(-4.5rem, 0.5rem, 0) }
    90% { transform: translate3d(3rem, -2.5rem, 0) }
    to { transform: translate3d(-3.5rem, 0, 0) }
}