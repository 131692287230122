.scrollableContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: all;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;
}

.mobileContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobileContainer .introContainer {
    margin-top: 0;
}

.mobileContainer .introContainer, .mobileContainer .renphilContainer, .mobileContainer .advisorsContainer, .mobileContainer .teamContainer {
    padding: 16px;
    width: 90vw;
}

.gradient {
    position: fixed;
    top: 0;
    right: 0;
    width: 80vw;
    height:200dvh;
    background-image: url('../../public/images/team-bg.svg');
    background-repeat: no-repeat;
    mix-blend-mode: hard-light;
    pointer-events: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollableContainer::-webkit-scrollbar {
    display: none;
}

.top {
    position: absolute;
    width: 100vw;
    height: 10px;
    top: 0;
}

.scrollInto {
    position: absolute;
    left: 0;
    width: 100vw;
    height: 10px;
    top: 30px;
}

.bottom {
    margin-top: 80px;
    width: 100vw;
    height: 10px;
}

.introContainer, .renphilContainer, .advisorsContainer, .teamContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    max-width: 620px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 8px;
    box-sizing: border-box;
    overflow: hidden;
    height: max-content;
}

.introContainer {
    margin-top: 100px;
}

.introInnerBorder, .advisorsInnerBorder, .teamInnerBorder {
    border: 1px solid rgba(255, 255, 255, 0.62);
    padding: 48px;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.introInnerBorder {
    padding: 48px 32px;
    gap: 24px;
}

.mobileContainer .introInnerBorder, .mobileContainer .advisorsInnerBorder, .mobileContainer .teamInnerBorder {
    padding: 48px 32px;
}

.advisorsInnerBorder {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.advisorsInnerBorder, .teamInnerBorder {
    align-items: flex-start;
}

.introContainer p, .renphilContainer p {
    justify-self: center;
    text-align: center;
}

.em {
    color: #C6EAD2;
}

.connector {
    width: 1px;
    flex-grow: 1;
    border-right: 1px dotted rgba(255, 255, 255, 0.62);
}

.heading {
    color: #EFFFFC;
    font-family: "P22 Mackinac";
    font-size: 22.719px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.682px;
    margin: 0;
}

.name {
    color: #EFFFFC;
    font-family: "P22 Mackinac";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
    margin: 0;
}

.teamContainer .heading {
    margin-bottom: 16px;
}

.advisor .name {
    margin-top: 16px;
}

.siteCreds .name {
    margin-top: 12px;
}

.subheading {
    color: #FFF;
    text-align: center;
    font-family: "P22 Mackinac";
    font-size: 22.719px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.6px;
    margin: 0;
}

.intro .body {
    color: rgba(247, 251, 255, 0.90);
    text-align: center;
    font-family: "Post Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.body {
    color: #ffffffb0;
    font-family: "Post Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.14px;
    margin: 0;
}

.divider {
    width: 100%;
    height: 1px;
    background: #EFFFFC;
    opacity: 0.16;
}

.advisors {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 42px;
    row-gap: 36px;
    margin-top: 24px;
}

.advisor .headshot {
    width: 82px;
}

.advisor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.team {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    margin-top: 24px;
}

.member {
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: flex-start;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
}

.member .headshot {
    width: 146px;
    height: 146px;
}

.siteCreds {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
    width: 100%;
}

.siteCreds .member {
    flex: 1;
    gap: 16px;
}

.siteCreds .headshot {
    width: 84px;
    height: 84px;
}

.mobileContainer .siteCreds {
    gap: 8px;
    margin-bottom: -10px;
}

.headshot {
    mix-blend-mode: color-burn;

}

.container .teamContainer {
    margin-bottom: 30dvh;
}

@media (max-width: 700px) {
    .advisors {
        display: flex;
        flex-direction: column;
    }
    .member {
        flex-direction: column;
        margin: 16px 0px;
    }
    .member .divider {
        margin: 8px 0px;
    }
    .container .teamContainer {
        margin-bottom: 0;
    }
    .body {
        font-size: 16px !important;
    }
}

@media (max-width: 500px) {
    .siteCreds {
        flex-direction: column;
    }
}