.container {
    background: white;
}

.title {
    font-family: "P22 Mackinac";
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 16px;
}

.passwordContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100dvh;
}

.info {
    font-family: 'Post Grotesk';
    font-size: 16px;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status {
    margin-top: 28px;
    font-family: 'Post Grotesk';
    font-size: 16px;
}

.passwordInput {
    font-family: 'Post Grotesk';
    font-size: 16px;
    outline: none;
    padding: 4px 8px;
    margin: 0;
    border: 1px solid black;
    border-radius: 0;
}

.inputContainerFailure {
    outline-offset: 2.5px;
    outline: 1.5px solid rgb(221, 89, 89);
    border-radius: 0px;
}

.submit {
    font-family: 'Post Grotesk';
    font-size: 16px;
    outline: none;
    border: none;
    margin-left: -1px;
    padding: 5px 8px;
    background: black;
    color: white;
}

.infoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.lock {
    width: 14px;
    margin-bottom: 1px;
}

.submit:hover {
    cursor: pointer;
}