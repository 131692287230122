.container, .mobileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100vw;
    height: calc(100dvh - 64px - 54px);
    background-color: black;
    overflow: hidden;
}

.invisible {
    all: unset;
    display: contents;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: calc(100vh - 64px - 54px);
}

.mobileContainer {
    height: auto;
}

.mobileContainer .title {
    display: none;
}

.mobileContainer .moon {
    display: block !important;
}

.gradient {
    position: absolute;
    width: 100vw;
    height: 100dvh;
    top: 0;
    left: 0;
    background-image: url('../../public/images/contact-bg.svg');
    background-repeat: no-repeat;
    mix-blend-mode: hard-light;
    background-position: bottom;
}

.contact {
    width: min(450px, 80vw);
    height: 100%;
    gap: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
}

.contact::before {
    position: absolute;
    margin-top: 4px;
    content: '';
    display: inline-block;
    width: inherit;
    height: 1px;
    background: white;
    opacity: 0.2;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
}

.main {
    display: flex;
    flex-direction: row;
    gap: 72px;
    justify-content: center;
    align-items: center;
}

.moon {
    height: 260px;
    max-height: 80vw;
    aspect-ratio: 1 / 1;
}

.title {
    color: #FFF;
    text-align: center;
    font-family: "P22 Mackinac";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.44px;
    margin-top: 0;
    margin-bottom: 64px;
}

.heading {
    color: #FFF;
    font-family: "P22 Mackinac";
    font-size: 22.719px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.682px;
    margin-top: 40px;
}

.body {
    color: #E4F5EA;
    font-family: "Post Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.14px;
}

.emailAddress {
    opacity: 72%;
}

.email {
    color: #FFF;
    font-family: "PP NeueBit";
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 36px;
    margin-bottom: 16px;
}

.emailContainer {
    padding: 0px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    pointer-events: all !important;
    text-decoration: none;
}

.emailContainer:hover {
    cursor: pointer;
}

.emailContainer, .emailContainer * {
    pointer-events: all !important;
}

.emailWrapper {
    margin-top: 32px;
    width: inherit;
    gap: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
}

.emailWrapper::before, .emailWrapper::after {
    content: '';
    display: inline-block;
    width: inherit;
    height: 1px;
    background: white;
    opacity: 0.2;
}

.emailWrapper::before {
    position: absolute;
    margin-top: 4px;
}

.goto {
    display: flex;
    padding: 4px;
    align-items: center;
    border: 0.5px solid rgba(255, 255, 255, 0.48);
    margin-top: 16px;
    box-sizing: content-box;
}

@media (max-width: 700px) {
    .container {
        height: calc(100dvh - 64px)
    }
    .body {
        font-size: 16px;
    }
}

@media (max-width: 900px) {
    .main {
        flex-direction: column;
    }
    .moon {
        display: none;
    }
}

@media (max-width: 500px) {
    .moon {
        width: 100%;
        height: 100%;
    }
}

@media (max-height: 800px) {
    .title {
        display: none;
    }
}