.container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    border-top: 0.5px solid #ffffff4a;
    padding: 16px 32px;
    mix-blend-mode: hard-light;
    margin-top: auto;
}

.name {
    color: #FFF;
    font-family: "PP NeueBit";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
}

.iconMark {
    width: 75px;
}