
.docs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    overflow: hidden;
}

.letterLeft {
    position: absolute;
    bottom: -20dvh;
    left: 8vw;
    height: 40vw;
    max-height: 380px;
    cursor: grab;
    pointer-events: all !important;
}

.docsMobile {
    position: absolute;
    bottom: -250px;
    width: 100vw;
    overflow: visible;
}

.docsMobile .letterLeft {
    position: absolute;
    bottom: 5vw;
    left: -15vw;
    width: 52vw;
    z-index: 1;
}

.docsMobile .letterRight {
    position: absolute;
    bottom: -10vw;
    left: -1vw;
    width: 48vw;
}

.docsMobile .darpaTag {
    position: absolute;
    bottom: 15vw;
    left: 45vw;
    width: 28vw;
}

.docsMobile .photoMuniza {
    position: absolute;
    bottom: 15vw;
    right: 8vw;
    width: 16vw;
}

.docsMobile .photoEmma {
    position: absolute;
    bottom: 8vw;
    right: -3vw;
    width: 16vw;
}

@keyframes letter-bounce-1 {
    0%, 100% {
        transform: rotate(166deg);
    }
    33% {
        transform: rotate(167deg) translateX(-5px) translateY(-5px);
    }
    66% {
        transform: rotate(164.5deg) translateX(5px) translateY(5px);
    }
}

@keyframes letter-bounce-2 {
    0%, 100% {
        transform: translateY(0px);
    }
    33% {
        transform: translateY(3px);
    }
    66% {
        transform: translateY(5px);
    }
}

.letterRight {
    position: fixed;
    bottom: -20dvh;
    left: 18vw;
    width: 40vw;
    max-width: 240px;
    pointer-events: all !important;
    cursor: grab;
    /* animation: letter-bounce-2 3s infinite ease-out; */
}

@keyframes tag-bounce {
    0%, 100% {
        transform: translateY(0);
    }
    33% {
        transform: translateX(2px) translateY(4px);
    }
    66% {
        transform: translateX(4px) translateY(7px);
    }
}

.constraints {
    position: fixed;
    top: calc(64px + 54px);
    left: 0;
    width: 100vw;
    height: calc(100vh - 64px - 54px);
    pointer-events: none;
}

.mobileConstraints {
    position: absolute;
    top: -36vh;
    left: 0;
    width: 100vw;
    height: 50vh;
    pointer-events: none;
}

.darpaTag {
    position: fixed;
    bottom: 4dvh;
    left: 46vw;
    width: 20vw;
    max-width: 180px;
    pointer-events: all !important;
    cursor: grab;
    /* animation: tag-bounce 4.5s infinite ease-out; */
}

.photoMuniza {
    position: fixed;
    width: 12vw;
    max-width: 128px;
    right: 28vw;
    bottom: 0;
    pointer-events: all !important;
    cursor: grab;
    /* animation: photo-slide-muniza 4s infinite ease-out; */
}

@keyframes photo-slide-muniza {
    0%, 100% {
        transform: rotate(-21deg) translateX(0);
    }
    33% {
        transform: rotate(-21deg) translateX(2px) translateY(4px);
    }
    66% {
        transform: rotate(-21deg) translateX(4px) translateY(7px);
    }
}

@keyframes photo-slide-emma {
    0%, 100% {
        transform: rotate(30deg) translateX(0);
    }
    33% {
        transform: rotate(30deg) translateX(2px) translateY(4px);
    }
    66% {
        transform: rotate(30deg) translateX(4px) translateY(7px);
    }
}

.photoEmma {
    position: fixed;
    width: 12vw;
    max-width: 128px;
    right: 23vw;
    bottom: 0;
    pointer-events: all !important;
    cursor: grab;
}

.rocket {
    position: fixed;
    max-width: 150px;
    bottom: 1dvh;
    left: 40vw;
    width: 20vw;
    cursor: grab;
    display: none;
    pointer-events: all !important;
}

.darpaTagBack {
    position: fixed;
    width: 18vw;
    max-width: 220px;
    right: 8vw;
    bottom: -1vw;
    cursor: grab;
    display: none;
    pointer-events: all !important;
}

@media (min-width: 800px) {
    .rocket {
        display: block;
    }
    .darpaTagBack {
        display: block;
    }
}