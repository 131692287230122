.container, .gradientContainer, .blobContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
}

.gradientContainer {
    mix-blend-mode: hard-light;
}

.blobContainer {
    mix-blend-mode: hard-light;
}

.topRightGradient {
    position: fixed;
    top: -25dvh;
    right: -25vw;
    width: 100vw;
    height: 80dvh;
    transform: rotate(30deg);
    border-radius: 100%;
    background: linear-gradient(180deg, #FFF 10.96%, #F1F1F1 18.15%, #CDE9D4 32.03%, #97BFBA 42.61%, rgba(126, 170, 236, 0.90) 70.37%, #4B6971 99.67%);
    mix-blend-mode: hard-light;
    filter: blur(80px);
    z-index: -1;
    pointer-events: none;
}

.greenRectangle {
    position: fixed;
    top: 0;
    right: -73vw;
    width: 120vw;
    height: 100dvh;
    transform: skewX(-25deg);
    background: linear-gradient(90deg, transparent, #CDF2D7 40%);
    mix-blend-mode: hard-light;
    z-index: -2;
    transition: 0.1s ease-out;
    pointer-events: none;
}

.eye {
    position: fixed;
    top: calc(50dvh - 18vw);
    right: -3vw;
    width: 50vw;
    aspect-ratio: 2308 / 1860;
    mix-blend-mode: color-burn;
    pointer-events: none;
}

.eyeMain {
    position: fixed;
    top: calc(50dvh - 6vw);
    right: 2vw;
    width: 36vw;
    aspect-ratio: 700 / 524;
    pointer-events: none;
}

.tree {
    position: fixed;
    bottom: -4vw;
    right: -2vw;
    width: 50vw;
    aspect-ratio: 2976 / 1676;
    mix-blend-mode: color-burn;
    pointer-events: none;
    opacity: 0.2;
}

.flowers {
    position: fixed;
    top: calc(50dvh - 20vw);
    right: 0;
    width: 50vw;
    mix-blend-mode: multiply;
    pointer-events: none;
}

.grassLeft, .grassRight {
    position: fixed;
    width: 50vw;
    aspect-ratio: 2976 / 1098;
    mix-blend-mode: color-burn;
    pointer-events: none;
    mask-image: linear-gradient(135deg, transparent, black);
}

.grassRight {
    mask-image: linear-gradient(45deg, transparent, black);
    bottom: 0;
    right: 0;
}

.grassLeft {
    mask-image: linear-gradient(-135deg, transparent, black);
    bottom: 0;
    left: 0;
}

.blueLight {
    margin-top: 10dvh;
    width: 300vw;
    height: 60dvh;
    transform: rotate(30deg);
    background: linear-gradient(180deg, color(display-p3 1 1 1) 10.96%, color(display-p3 0.9451 0.9451 0.9451) 18.15%, color(display-p3 0.8039 0.9137 0.8314) 32.03%, color(display-p3 0.5922 0.749 0.7294) 42.61%, color(display-p3 0.4956 0.6678 0.9261 / 0.90) 70.37%, color(display-p3 0.2941 0.4118 0.4431) 99.67%);
    mix-blend-mode: hard-light;
    filter: blur(90px);
    position: fixed;
    top: 0;
    left: -65vw;
    z-index: 1;
    pointer-events: none;
}

.black {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background: black;
    z-index: -10;
    pointer-events: none;
}

.introContainer {
    mix-blend-mode: hard-light;
}

.introGradient {
    position: fixed;
    top: 0;
    left: 0vw;
    width: 100vw;
    height: 300dvh;
    pointer-events: none;
    opacity: 0.8;
}

.diagonalGradient {
    position: fixed;
    top: calc(54px + 64px);
    right: 0;
    width: 80vw;
    z-index: 0;
    pointer-events: none;
}

.blob {
    position: fixed;
    top: 0;
    right: -50vw;
    width: 200vw;
    z-index: -1;
    pointer-events: none;
}

@media (max-width: 1200px) {
    .greenRectangle {
        right: -82vw;
    }
}

@media (max-width: 800px) {
    .greenRectangle {
        right: -90vw;
    }
}

@media (max-width: 650px) {
    .eye {
        top: calc(50dvh - 30vw);
        right: -30vw;
        width: 100vw;
    }
    .tree {
        bottom: 20dvh;
        right: -30vw;
        width: 100vw;
    }
    .blueLight {
        margin-top: 10dvh;
        width: 300vw;
        height: 60dvh;
        left: 0vw;
        opacity: 1;
    }
    .eyeMain {
        top: calc(50dvh - 12vw);
        right: -24vw;
        width: 75vw;
        z-index: -2;
    }
}

@media (max-width: 700px) {
    .diagonalGradient {
        top: 8dvh;
    }    
}