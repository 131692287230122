.container {
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gif {
    max-width: 400px;
    width: 80vw;
}