.pixelBackground {
  position: fixed;
  top: calc(64px);
  left: 0;
  width: 100vw;
  height: calc(100dvh - 64px);
  display: flex;
  position: fixed;
  pointer-events: none;
  z-index: 1000;
}

.column {
  width: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.block {
  width: 40px;
  height: 40px;
  background-color: black;
}